import {
  categoryDrinksTitle,
  product2Plus1Gratis,
  vosSections,
} from "../constants/constants";
import {
  cancelledStatusCode,
  closedStatusCode,
  finishedStatusCode,
} from "../components/Admin/adminEnum";

export const getCartItemPriceUnit = (cartItem) => {
  let price = cartItem.product.price * 1;
  if (cartItem.additionItems && cartItem.additionItems.length > 0) {
    for (let additionItem of cartItem.additionItems) {
      const key = additionItem.groupTitle + ":" + additionItem.title;;
      const qty = (cartItem.hasOwnProperty('additionItemsQty') && cartItem.additionItemsQty.hasOwnProperty(key)) ? cartItem.additionItemsQty[key] : 1;
      price =
        price +
        (additionItem.surcharge * qty > 0 ? additionItem.surcharge * qty : 0);
    }
  }
  return price;
};

export const getCartItemPriceTotal = (cartItem) => {
  return cartItem.quantity * getCartItemPriceUnit(cartItem);
};

export const getCartPriceSubtotal = (cart) => {
  let price = 0;
  for (let cartItem of cart.items) {
    // if (isProductInRangeOfGoods(rog, cartItem.product)) {
    price = price + getCartItemPriceTotal(cartItem);
    // }
  }
  if (cart.saleActions && cart.saleActions.length > 0) {
    for (let action of cart.saleActions) {
      price = price + action.price;
    }
  }

  return price;
};

export const getCartPriceSubtotalForCapacityCalculation = (
  cart,
  vosSection
) => {
  let price = 0;
  for (let cartItem of cart.items) {
    if (
      !cartItem.product.sections || // položka není vyráběna v žádné VOS sekci
      cartItem.product.sections.length === 0 || // položka nemá žádnou VOS sekci
      !cartItem.product.sections.includes(vosSection) // položka je vyráběna v jiné než požadované sekci
    ) {
      continue; // nepočítej s položkou
    }
    let addPrice = getCartItemPriceTotal(cartItem);
    if (cartItem.product.fullTitle === product2Plus1Gratis) {
      // pokud produkt je Pizza Zdarma, počítáme s ním do kapacity jako by stál 159,-
      addPrice = addPrice + 159;
    }
    if (cartItem.product?.categories?.includes(categoryDrinksTitle)) {
      // pokud produkt je v kategorii Nápoje, počítáme s ním do kapacity jako by stál 0,-
      addPrice = 0;
    }
    price = price + addPrice;
  }
  if (cart.saleActions && cart.saleActions.length > 0) {
    for (let action of cart.saleActions) {
      price = price + action.price;
    }
  }

  return price;
};

export const getCartPriceDelivery = (cart) => {
  return cart.servicePrice;
};

export const getCartPriceTotal = (cart) => {
  return getCartPriceSubtotal(cart) + getCartPriceDelivery(cart);
};

export const formatPrice = (price, ifZero = "zdarma") => {
  if (price * 1 === 0 && ifZero === "zdarma") {
    return ifZero;
  }

  return price + ",-";
};

export const formatSurchargePrice = (price) => {
  if (price * 1 === 0) {
    return "";
  }

  if(price * 1 < 0){
    return <>-&nbsp;{price*-1},-</>;
  }

  return <>+&nbsp;{price},-</>;
};
