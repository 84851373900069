import {
    LOYALTY_PROGRAM_TOKEN_SET,
    LOYALTY_PROGRAM_CUSTOMER_SET,
    LOYALTY_PROGRAM_BENEFITS_SET,
    LOYALTY_PROGRAM_DIALOG_OPEN,
    LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET
} from "../constants/actionTypes";

const initialState = {
    token: null,
    customer: null,
    benefits: [],
    dialogOpen: false,
    selectedBenefitCode: null
};

const loyaltyProgramReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOYALTY_PROGRAM_TOKEN_SET: {
            const stateCopy = { ...state };
            stateCopy.token = action.payload;

            return stateCopy;
        }
        case LOYALTY_PROGRAM_CUSTOMER_SET: {
            const stateCopy = { ...state };
            stateCopy.customer = action.payload;

            return stateCopy;
        }
        case LOYALTY_PROGRAM_BENEFITS_SET: {
            const stateCopy = { ...state };
            stateCopy.benefits = action.payload;

            return stateCopy;
        }
        case LOYALTY_PROGRAM_DIALOG_OPEN: {
            const stateCopy = { ...state };
            stateCopy.dialogOpen = action.payload;

            return stateCopy;
        }
        case LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET: {
            const stateCopy = { ...state };
            stateCopy.selectedBenefitCode = action.payload;

            return stateCopy;
        }
        default: {
            return state;
        }
    }
};

export default loyaltyProgramReducer;
