import {
    LOYALTY_PROGRAM_DIALOG_OPEN, SET_POINT_OF_SALE,
} from "../constants/actionTypes";

export const loyaltyProgramMiddleware = (storeAPI) => (next) => (action) => {
    let result = next(action); //vykonej akci (modifikuj state)
    if ([SET_POINT_OF_SALE].includes(action.type) //byl-li state modifikován alespoň jednou z těchto akcí
    ) {
        storeAPI.dispatch({
            type: LOYALTY_PROGRAM_DIALOG_OPEN, payload: true,
        });
    }

    return result;
};
