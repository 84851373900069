import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    LOYALTY_PROGRAM_BENEFITS_SET,
    LOYALTY_PROGRAM_CUSTOMER_SET,
    LOYALTY_PROGRAM_DIALOG_OPEN,
    LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET,
    LOYALTY_PROGRAM_TOKEN_SET
} from "../../constants/actionTypes";
import {apiLoyaltyGetBenefits, apiLoyaltyGetCustomer} from "../../api/endpoints";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    TableCell,
    TableRow
} from "@material-ui/core";
import {getAdditionItemsOfAdditionGroup} from "../../utils/data";
import {formatPrice, formatSurchargePrice, getCartItemPriceTotal} from "../../utils/price";
import {colors} from "../../theme/theme";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import {getImgSrcForAdditionItem} from "../../utils/imgSrc";
import {
    meatSectionProductionInfo_isVisibleForProduct, meatSectionProductionInfo_text2
} from "../MeatSectionProductionInfo/MeatSectionProductionInfo";
import {addItemToCart, updateCartItem} from "../../actions/cartActions";
import {pointOfSaleToV} from "../../constants/pointOfSales";
import {productBenefit} from "../../constants/constants";
import {isBenefit} from "./loyaltyProgram";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const ziskatHodnotuParametruB = (url, paramName) => {
    // Rozdělení URL na části pomocí otazníku
    var castiUrl = url.split("?");

    // Pokud existuje druhá část (s parametry), pokračujeme
    if (castiUrl.length > 1) {
        // Rozdělení parametrů pomocí ampersandu
        var parametry = castiUrl[1].split("&");

        // Procházení všech parametrů a hledání parametru "b"
        for (var i = 0; i < parametry.length; i++) {
            var parametr = parametry[i].split("=");
            // Pokud nalezneme parametr "b", vrátíme jeho hodnotu
            if (parametr[0] === paramName) {
                return parametr[1];
            }
        }
    }

    // Pokud nenalezneme parametr "b", vrátíme null
    return null;
}

const useStyles = makeStyles((theme) => ({
    accordion: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: "8px !important",
        marginTop: "0 !important",
        borderRadius: "0px !important",
        borderStyle: "none !important",
        overflow: "hidden",
        "&:before": {
            opacity: "0 !important",
        },
        "& .MuiAccordionSummary-root": {
            minHeight: "48px !important", margin: "0 !important", backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        "& .MuiAccordionSummary-content": {
            margin: "8px 0 !important",
        },
        "& .MuiAccordionDetails-root": {
            paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0,
        },
    }, additionGroupTitle: {
        fontWeight: 300, fontSize: "100%", "& span": {
            fontSize: "100%", fontWeight: 400,
        },
    }, additionGroupSubtitle: {
        fontSize: "90%", "&.warning": {
            fontWeight: 600, color: colors.RED.main,
        },
    }, formControl: {
        "& .MuiSelect-selectMenu": {
            whiteSpace: "normal !important",
        },
    }, additionTitle: {
        fontWeight: 200,
    }, additionPrice: {
        fontWeight: 200, textAlign: "right", paddingRight: theme.spacing(0.5),
    }, additionItem: {
        height: 42,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: "rgba(0, 0, 0, 0.75)",
        backgroundColor: "rgba(0, 0, 0, 0.00)",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .check": {},
        "& .img": {
            marginLeft: theme.spacing(1), marginRight: theme.spacing(1), height: "100%", "& img": {
                height: "100%",
            },
        },
        "& .label": {
            flexGrow: 1, marginLeft: theme.spacing(1), fontSize: "100%", textTransform: "none",
        },
        "& .price": {
            textAlign: "right", fontSize: "100%",
        },
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
        "&.selected": {
            color: theme.palette.primary.main,
        },
        "&.disabled": {
            opacity: 0.4, cursor: "default", "& input": {
                cursor: "default",
            }, "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0)",
            },
        },
    },
}));

const groupTitles = ['Omáčky zdarma', 'Zvětšení přílohy zdarma', 'Přílohy zdarma', 'Káva zdarma', 'Nápoje zdarma', 'Pizza zdarma'];

const LoyaltyProgramProviderDialogGroup = (props) => {
    const {groupTitle, customer, benefits, selectedBenefitCode, handleSelectBenefitCode} = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    if(benefits.filter((b) => b.content['groupTitle'] === groupTitle && customer.benefitCodes.includes(b.code)).length === 0){
        return <></>
    }

    return (<Accordion
            variant={"elevation"}
            elevation={0}
            className={classes.accordion}
            expanded={open}
            onChange={() => {
                setOpen((prev) => !prev);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.additionGroupTitle}>
                    {groupTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    {customer.benefitCodes.map((code) => {
                        const index = benefits.findIndex((b) => (b.code === code));
                        if (index > -1) {
                            const benefit = benefits[index];
                            if(benefit.content['groupTitle'] === groupTitle){
                                const selected = benefit.code === selectedBenefitCode;
                                const disabled = false;
                                return (<Grid item xs={12} key={benefit.code}>
                                    <Box
                                        className={classes.additionItem + (selected ? " selected" : "") + (disabled ? " disabled" : "")}
                                        onClick={() => {
                                            handleSelectBenefitCode(benefit.code);
                                        }}
                                    >
                                        <Box className={"check"}>
                                            <Checkbox
                                                checked={selected}
                                                color={"primary"}
                                                icon={<CheckBoxOutlineBlankOutlinedIcon/>}
                                                checkedIcon={<CheckBoxOutlinedIcon/>}
                                            />
                                        </Box>
                                        {benefit.imageUrl && (<Box className={"img"}>
                                            <img
                                                src={'xxx'}
                                                alt={benefit.title}
                                            />
                                        </Box>)}
                                        <Box className={"label"}>
                                            <div>
                                                {benefit.title}{" "}
                                                <span style={{fontSize: "80%", fontWeight: 300}}>
            {/*{additionItem.allergens && <>({additionItem.allergens})</>}*/}
          </span>
                                            </div>
                                        </Box>
                                        <Box className={"price"}>
                                            {formatSurchargePrice(benefit.price)}
                                        </Box>
                                    </Box>
                                </Grid>);
                            }
                        }
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>);
}

const LoyaltyProgramProviderDialog = () => {

    const dispatch = useDispatch();
    const open = useSelector((state) => (state.loyaltyProgram.dialogOpen && !!state.loyaltyProgram.customer && state.loyaltyProgram.customer.benefitCodes.length > 0));
    const benefits = useSelector((state) => {
        const posId = state.cart.pointOfSaleId;
        if (!posId) {
            return [];
        }
        const pId = pointOfSaleToV[posId];
        return state.loyaltyProgram.benefits.filter((b) => {
            return b.content[pId] === true;
        }).sort((a, b) => {
            const aX = groupTitles.findIndex((o) => o === a.content['groupTitle']);
            const bX = groupTitles.findIndex((o) => o === b.content['groupTitle']);
            if (aX === bX) {
                return a.content['position'] - b.content['position'];
            }

            return aX - bX;
        });
    });
    const cart = useSelector((state) => (state.cart));
    const customer = useSelector((state) => (state.loyaltyProgram.customer));
    const selectedBenefitCode = useSelector((state) => (state.loyaltyProgram.selectedBenefitCode));

    const classes = useStyles();

    const handleClose = () => {
        dispatch({
            type: LOYALTY_PROGRAM_DIALOG_OPEN, payload: false,
        });
    }

    const handleSelectBenefitCode = (benefitCode) => {
        dispatch({
            type: LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET, payload: benefitCode,
        });
    }

    const handleSubmit = (selectedBenefitCode) => {
        const cartItemIndex = cart.items.findIndex((c) => isBenefit(c));
        if (selectedBenefitCode === -1) {
            const cartItem = {
                quantity: 1, product: {
                    "fullTitle": -1,
                    "title": 'Vyberu si později',
                    "subtitle": productBenefit,
                    "description": "",
                    "imgSrc": "",
                    "categories": [],
                    "sections": [],
                    "allergens": "",
                    "price": 0,
                    "externalId_dotykackaCloudVyskov": "",
                    "externalId_dotykackaCloudUherskyBrod": "",
                    "externalId_dotykackaCloudTestovaci": "",
                    "additionGroups": [],
                    "position": 999999999,
                    "tagPizzaOfMonth": false,
                    "tagBurgerOfMonth": false,
                    "notCartInsertable": false,
                    "dotykacka": [],
                    "invisible": false,
                    "meta": {
                        "pointsReduction": 0
                    }
                }, additionItems: [], takeAway: true,
            };
            if (cartItemIndex === -1) {
                dispatch(addItemToCart(cartItem));
            } else {
                dispatch(updateCartItem({
                    cartItem: cartItem, index: cartItemIndex,
                }));
            }
            handleClose();
            handleSelectBenefitCode(-1);
        } else {
            const index = benefits.findIndex((b) => (b.code === selectedBenefitCode));
            if (index > -1) {
                const benefit = benefits[index];
                const cartItem = {
                    quantity: 1, product: {
                        "fullTitle": benefit.code,
                        "title": benefit.title,
                        "subtitle": productBenefit,
                        "description": "",
                        "imgSrc": benefit.imageURL,
                        "categories": [],
                        "sections": benefit.content.sections,
                        "allergens": "",
                        "price": benefit.price,
                        "externalId_dotykackaCloudVyskov": benefit.content["externalId_dotykackaCloudVyskov"],
                        "externalId_dotykackaCloudUherskyBrod": benefit.content["externalId_dotykackaCloudUherskyBrod"],
                        "externalId_dotykackaCloudTestovaci": benefit.content["externalId_dotykackaCloudTestovaci"],
                        "additionGroups": [],
                        "position": 999999999,
                        "tagPizzaOfMonth": false,
                        "tagBurgerOfMonth": false,
                        "notCartInsertable": false,
                        "dotykacka": [],
                        "invisible": false,
                        "meta": {
                            "pointsReduction": benefit.pointsReduction
                        }
                    }, additionItems: [], takeAway: true,
                };
                if (cartItemIndex === -1) {
                    dispatch(addItemToCart(cartItem));
                } else {
                    dispatch(updateCartItem({
                        cartItem: cartItem, index: cartItemIndex,
                    }));
                }
                handleClose();
            }
        }
    }

    if (!customer) {
        return <></>
    }

    return (<Dialog open={open && benefits.length > 0} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>Vyberte si svůj dárek</DialogTitle>
        <DialogContent style={{padding: 0}}>
            <Grid container>
                {groupTitles.map((groupTitle) => {
                    return (<Grid item xs={12} key={groupTitle}>
                            <LoyaltyProgramProviderDialogGroup groupTitle={groupTitle} customer={customer}
                                                               benefits={benefits}
                                                               selectedBenefitCode={selectedBenefitCode}
                                                               handleSelectBenefitCode={handleSelectBenefitCode}/>
                        </Grid>)
                })}

            </Grid>
        </DialogContent>
        <DialogActions
            style={{
                display: "flex", flexDirection: "column", alignItems: "normal",
            }}
        >

            <Box
                style={{
                    margin: 0,
                    padding: 8,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: 'space-between'
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    size={"large"}
                    onClick={() => {
                        handleSubmit(-1);
                    }}
                >
                    {'Vyberu si později'}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size={"large"}
                    disabled={!selectedBenefitCode || selectedBenefitCode === -1}
                    onClick={() => {
                        handleSubmit(selectedBenefitCode);
                    }}
                >
                    {'Vložit do košíku'}
                </Button>
            </Box>
        </DialogActions>
    </Dialog>);
}

const LoyaltyProgramProvider = (props) => {
    const dispatch = useDispatch();

    const handleLoad = () => {
        axios.get(apiLoyaltyGetBenefits()).then((response) => {
            if (response.status === 200) {
                dispatch({type: LOYALTY_PROGRAM_BENEFITS_SET, payload: response.data});
            }
        });
    };

    const resolveBParameter = () => {
        const url = window.location.href;
        const b = ziskatHodnotuParametruB(url, 'b');
        if (!!b) {
            dispatch({type: LOYALTY_PROGRAM_TOKEN_SET, payload: b});
            const email = atob(b.substring(1));
            axios.post(apiLoyaltyGetCustomer(), {email}).then((response) => {
                if (response.status === 200) {
                    dispatch({type: LOYALTY_PROGRAM_CUSTOMER_SET, payload: response.data});
                    handleLoad();
                }
            }).finally(() => {
            });
        }
    }

    useEffect(() => {
        resolveBParameter();
    }, []);

    return (<>
        <LoyaltyProgramProviderDialog/>
        {props.children}
    </>);

}

export default LoyaltyProgramProvider;