import React from "react";
import LoyaltyProgram from "../../components/Admin/LoyaltyProgram/LoyaltyProgram";
import {Container} from "@material-ui/core";

const LoyaltyProgramAdminView = () => {
    return (<Container style={{marginTop: 32}}>
            <LoyaltyProgram/>
        </Container>);
};

export default LoyaltyProgramAdminView;
