import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { colors } from "../theme/theme";
import Clock from "../components/Clock/Clock";
import MenuIcon from "@material-ui/icons/Menu";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArchiveIcon from "@material-ui/icons/Archive";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DescriptionIcon from "@material-ui/icons/Description";
import CachedIcon from "@material-ui/icons/Cached";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Link } from "react-router-dom";
import { staticPages } from "../constants/staticPages";
import { useDispatch, useSelector } from "react-redux";
import { SET_ADMIN_LOCAL_BUSINESS } from "../constants/actionTypes";
import SyncIcon from "@material-ui/icons/Sync";
import {
  checkNaiveAuth,
  getAuth,
  isAuth,
  removeAuth,
  roleAdmin,
  roleLocalBusinessAdmin,
  setAuth,
} from "../auth/auth";
import LogIn from "../components/LogIn/LogIn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import axios from "axios";
import { baseUrl } from "../constants/constants";
import InternalCommission from "../components/Admin/Capacity/InternalCommission/InternalCommission";
import InternalCommissionTimelineV2 from "../components/Admin/Capacity/InternalCommission/InternalCommissionTimelineV2";
import { Timer } from "@material-ui/icons";

export const commissionVariantHorizontal = window.screen.width < 576;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: colors.BLACK.main,
    color: colors.BLACK.text,
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: commissionVariantHorizontal ? 65 : 130,
  },
  menu: {
    "& a": {
      color: colors.BLACK.main,
      textDecoration: "none",
    },
  },
}));

const BarDrawer = (props) => {
  const { handleSetLb, localBusinesses, handleLogout } = props;
  const classes = useStyles();

  const adminRole = !!getAuth() ? getAuth().role : null;

  const [open, setOpen] = React.useState(false);

  const invalidateCache = () => {
    const url = baseUrl + "/cache/invalidate";
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          alert("Mezipamět byla resetována.");
        } else {
          alert("Mezipamět nebyla resetována.");
        }
      })
      .catch((error) => {
        alert("Mezipamět nebyla resetována.");
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const dispatch = useDispatch();

  const list = () => (
    <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List className={classes.menu}>
        <Link to={"/admin"} onClick={() => {
          dispatch({
            type: 'ADMIN_VIEW_SET',
            payload: {
              adminView: 'CREATE'
            }
          })
        }}>
          <ListItem button>
            <ListItemIcon>
              <LibraryAddIcon />
            </ListItemIcon>
            <ListItemText primary={"Nová objednávka"} />
          </ListItem>
        </Link>
        <Link to={"/admin"}  onClick={() => {
          dispatch({
            type: 'ADMIN_VIEW_SET',
            payload: {
              adminView: 'LIST'
            }
          })
        }}>
          <ListItem button>
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary={"Objednávky"} />
          </ListItem>
        </Link>
        <Link to={"/admin/archive"}>
          <ListItem button>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary={"Archiv objednávek"} />
          </ListItem>
        </Link>
        {[roleAdmin, roleLocalBusinessAdmin].includes(adminRole) && (
          <>
            <Link to={"/admin/report"}>
              <ListItem button>
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary={"Report objednávek"} />
              </ListItem>
            </Link>
            <Link to={"/admin/capacity"}>
              <ListItem button>
                <ListItemIcon>
                  <ViewComfyIcon />
                </ListItemIcon>
                <ListItemText primary={"Kapacity"} />
              </ListItem>
            </Link>
            <Link to={"/admin/internal-commission-prescription"}>
              <ListItem button>
                <ListItemIcon>
                  <Timer />
                </ListItemIcon>
                <ListItemText primary={"Pravidelné přestávky a výroby"} />
              </ListItem>
            </Link>
            <Link to={"/admin/products"}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Produkty"} />
              </ListItem>
            </Link>
            <Link to={"/admin/product-addition-items"}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Přísady"} />
              </ListItem>
            </Link>
          </>
        )}
        <Link to={"/admin/loyalty-program"}>
          <ListItem button>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary={"Věrnostní program"} />
          </ListItem>
        </Link>
        {[roleAdmin].includes(adminRole) && (
          <>
            <Divider />
            {staticPages.map((page, index) => {
              return (
                <Link to={"/admin/pages/" + page.pageSlug} key={index}>
                  <ListItem button>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={page.title} />
                  </ListItem>
                </Link>
              );
            })}
            <Divider />
            {localBusinesses.map((lb) => {
              return (
                <ListItem button onClick={() => handleSetLb(lb)} key={lb.title}>
                  <ListItemIcon>
                    <SyncIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Přepnout na " + lb.title} />
                </ListItem>
              );
            })}
          </>
        )}
        <Divider />
        <ListItem button onClick={invalidateCache}>
          <ListItemIcon>
            <CachedIcon />
          </ListItemIcon>
          <ListItemText primary={"Resetovat mezipaměť"} />
        </ListItem>
        <Divider />
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Odhlásit se"} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

const NavBar = ({
  title,
  localBusiness,
  handleSetLb,
  localBusinesses,
  handleLogout,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={classes.appBar}
        id="appBar"
        color={"secondary"}
      >
        <Toolbar className={classes.toolbar}>
          <Box display={"flex"} style={{ width: "100%" }} alignItems={"center"}>
            <Box>
              <BarDrawer
                handleSetLb={handleSetLb}
                localBusinesses={localBusinesses}
                handleLogout={handleLogout}
              />
            </Box>
            <Box>
              <Typography variant="h6">
                <span style={{ color: "red" }}>{localBusiness?.title}</span>{" "}
                {title}
              </Typography>
            </Box>
            {!commissionVariantHorizontal && (
              <Box flexGrow={1} style={{ textAlign: "center" }}>
                {localBusiness?.title === "Vyškov" && <InternalCommission />}
              </Box>
            )}

            <Box style={{ textAlign: "right" }}>
              <Typography variant="h4">
                <Clock />
              </Typography>
            </Box>
          </Box>
          {!commissionVariantHorizontal && (
            <Box style={{ padding: 8 }}>
              {localBusiness?.title === "Vyškov" && (
                <InternalCommissionTimelineV2 />
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />
    </React.Fragment>
  );
};

const LayoutAdmin = (props) => {
  const { children, title } = props;

  const dispatch = useDispatch();
  const localBusiness = useSelector((state) => state.app.adminLocalBusiness);
  const localBusinesses = useSelector(
    (state) => state.settings.localBusinesses
  );

  const [error, setError] = React.useState("");
  const [loggedIn, setLoggedIn] = React.useState(true);

  const handleSetLb = (lb) => {
    dispatch({
      type: SET_ADMIN_LOCAL_BUSINESS,
      payload: lb,
    });
  };

  const onLogInSubmit = (form) => {
    setError("");
    const check = checkNaiveAuth(form.loginName, form.password);
    if (!!check) {
      const lb = localBusinesses.find(
        (l) => l.title === check.localBusinessTitle
      );
      setLoggedIn(true);
      handleSetLb(lb);
      setAuth(check);
    } else {
      setError("Nesprávné přihlašovací údaje!");
    }
  };

  const handleLogout = () => {
    removeAuth();
    setLoggedIn(false);
  };

  if (!isAuth() || !loggedIn) {
    return <LogIn onSubmit={onLogInSubmit} error={error} />;
  }

  return (
    <React.Fragment>
      {localBusiness && (
        <>
          <NavBar
            title={title}
            localBusiness={localBusiness}
            handleSetLb={handleSetLb}
            localBusinesses={localBusinesses}
            handleLogout={handleLogout}
          />
          {children}
        </>
      )}
      {!localBusiness && (
        <Container>
          <Typography
            variant={"h3"}
            style={{ marginTop: 10 * 8, textAlign: "center" }}
          >
            Zvolte provozovnu:
          </Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={10}
            style={{ marginTop: 5 * 8 }}
          >
            {localBusinesses.map((lb) => {
              return (
                <Grid item key={lb.title}>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => handleSetLb(lb)}
                    size={"large"}
                    style={{
                      fontSize: 40,
                    }}
                  >
                    {lb.title}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
};

export default LayoutAdmin;
