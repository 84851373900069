//settings
export const FETCH_SETTINGS_INIT = "FETCH_SETTINGS_INIT";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";

//rangeOfGoods
export const FETCH_RANGE_OF_GOODS_INIT = "LOAD_RANGE_OF_GOODS_INIT";
export const FETCH_RANGE_OF_GOODS_SUCCESS = "LOAD_RANGE_OF_GOODS_SUCCESS";

//app
export const APP_LOADING = "APP_LOADING";
export const DELIVERY_DIALOG = "DELIVERY_DIALOG";
export const SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE =
  "SALE_2_PLUS_1_GRATIS_DIALOG_TOGGLE";

export const SET_ADMIN_LOCAL_BUSINESS = "SET_ADMIN_LOCAL_BUSINESS";
export const SET_ADMIN_SELECTED_STATUSES = "SET_ADMIN_SELECTED_STATUSES";

export const FETCH_ADMIN_CAPACITY_INIT = "FETCH_ADMIN_CAPACITY_INIT";
export const FETCH_ADMIN_CAPACITY_SUCCESS = "FETCH_ADMIN_CAPACITY_SUCCESS";

export const SET_ADMIN_COMMISSION_CREATED_AT =
  "SET_ADMIN_COMMISSION_CREATED_AT";

//cart
export const SET_CART = "SET_CART";
export const EMPTY_CART = "EMPTY_CART";
export const SET_POINT_OF_SALE = "SET_POINT_OF_SALE";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const CHANGE_CART_ITEM_QUANTITY_ADD = "CHANGE_CART_ITEM_QUANTITY_ADD";
export const CHANGE_CART_ITEM_QUANTITY_SUB = "CHANGE_CART_ITEM_QUANTITY_SUB";
export const APPLY_SALE_ACTIONS = "APPLY_SALE_ACTIONS";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const SET_TAKEOVER_DATETIME = "SET_TAKEOVER_DATETIME";

//loyaltyProgram
export const LOYALTY_PROGRAM_TOKEN_SET = 'LOYALTY_PROGRAM_TOKEN_SET';
export const LOYALTY_PROGRAM_CUSTOMER_SET = 'LOYALTY_PROGRAM_CUSTOMER_SET';
export const LOYALTY_PROGRAM_BENEFITS_SET = 'LOYALTY_PROGRAM_BENEFITS_SET';
export const LOYALTY_PROGRAM_DIALOG_OPEN = 'LOYALTY_PROGRAM_DIALOG_OPEN';
export const LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET = 'LOYALTY_PROGRAM_SELECTED_BENEFIT_CODE_SET';
