import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  getDeliveryTextForCart,
  getTotalQuantity,
  showDeliveryInCart,
} from "../../utils/cart";
import {
  formatPrice,
  getCartPriceDelivery,
  getCartPriceSubtotal,
  getCartPriceTotal,
} from "../../utils/price";
import { useDispatch, useSelector } from "react-redux";

import CartItem from "./CartItem";
import React from "react";
import { colors } from "../../theme/theme";
import { Link } from "react-router-dom";
import { isProductInRangeOfGoods } from "../../utils/data";
import { deliveryDialogOpen } from "../../actions/appActions";
import {
  getCountOfPizza30FreeInCart,
  getCountOfPizza30InCart,
} from "../Sale2Plus1/sale2plus1";
import {
  addressXNA,
  commissionOriginInternet,
  commissionOriginTelephone,
  KIOSK_ENABLED,
  KIOSK_POINT_OF_SALE,
  vosSections,
} from "../../constants/constants";
import CommissionCartSectionButton from "../Admin/CommissionDetail/CommissionCartSectionButton";
import {
  contextAdmin,
  contextReport,
  contextCheckout,
  contextHome,
} from "../../constants/contexts";
import { commissionVariantHorizontal } from "../Admin/CommissionDetail/CommissionDetail";
import { emptyCart, setPointOfSale } from "../../actions/cartActions";
import { servicePrice } from "../../utils/delivery";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  meatSectionProductionInfo_isVisibleForCart,
  meatSectionProductionInfo_text,
} from "../MeatSectionProductionInfo/MeatSectionProductionInfo";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "150%",
    fontWeight: 900,
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionContainer: {
    flexDirection: commissionVariantHorizontal ? "column" : "row",
  },
  section: {
    borderLeft: commissionVariantHorizontal
      ? "none"
      : "solid 1px rgba(0, 0, 0, 0.12)",
    borderBottom: commissionVariantHorizontal
      ? "solid 1px rgba(0, 0, 0, 0.12)"
      : "none",
    "&:last-child": {
      borderBottom: "none",
    },
    // "&:nth-child(1)": {
    //   background: "#fbe9e7",
    // },
    // "&:nth-child(2)": {
    //   background: "#efebe9",
    // },
    // "&:nth-child(3)": {
    //   background: "#fafafa",
    // },
  },
  sectionTitle: {
    fontSize: "200%",
    fontWeight: 800,
    paddingLeft: theme.spacing(1),
  },
  items: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subtotal: {
    fontSize: "120%",
    display: "flex",
    fontWeight: 500,
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& .changeLink": {
      fontSize: "80%",
      fontWeight: 200,
      "& a": {
        cursor: "pointer",
        color: colors.RED.main,
      },
    },
    "& .address": {
      fontSize: "80%",
      fontWeight: 200,
    },
  },
  total: {
    fontSize: "130%",
    display: "flex",
    fontWeight: 800,
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  alert: {
    textAlign: "center",
    fontWeight: 500,
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#ff0000",
  },
  button: {
    textAlign: "center",
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    "& a": {
      color: theme.palette.primary.contrastText,
      textDecoration: "none",
    },
  },
  emptyCart: {
    textAlign: "center",
    fontSize: "140%",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  infoContainer: {},
  infoText: {
    textAlign: "center",
    fontWeight: 400,
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const getPadding = (context) => {
  return {
    paddingLeft: context === contextAdmin ? 8 : 0,
    paddingRight: context === contextAdmin ? 8 : 0,
    paddingBottom: context === contextAdmin ? 2 : 8,
    paddingTop: context === contextAdmin ? 2 : 8,
  };
};

const Cart = (props) => {
  const {
    cart,
    editable,
    handleEditCartItem = null,
    handleToggleTakeAway = null,
    handleChangeCommission = null,
    context,
    markSectionDone,
    handleSubmit,
    defaultErrors,
    isButtonDisabled,
    dateTimePickerComponent = <></>,
  } = props;

  const dispatch = useDispatch();
  const rangeOfGoods = useSelector((state) => state.rangeOfGoods);
  const settings = useSelector((state) => state.settings);
  const classes = useStyles();

  const [errors, setErrors] = React.useState([]);

  const [kioskOpen, setKioskOpen] = React.useState(true);

  const kioskReset = () => {
    dispatch(emptyCart());
    const pos = settings.pointOfSales.find(
      (pos) => pos.id === KIOSK_POINT_OF_SALE
    );
    dispatch(setPointOfSale(pos, addressXNA, servicePrice(pos)));
  };

  const getPointOfSale = () => {
    return settings.pointOfSales.find((pos) => pos.id === cart.pointOfSaleId);
  };

  const validationRule1 = () => {
    let iMax = cart.items.length;
    for (let i = 0; i < iMax; i++) {
      if (!isProductInRangeOfGoods(rangeOfGoods, cart.items[i].product)) {
        return "Objednávka obsahuje položky, které zvolená provozovna neprodává. Odstraňte je prosím.";
      }
    }
    return null;
  };

  const validationRule2 = () => {
    if (getPointOfSale()) {
      if (getCartPriceSubtotal(cart) < getPointOfSale().minimalPrice) {
        return (
          "Minimální hodnota objednávky (mezisoučet) je " +
          formatPrice(getPointOfSale().minimalPrice, 0) +
          "."
        );
      }
    }
    return null;
  };

  const validationRule3 = () => {
    const pizza30s = getCountOfPizza30InCart(cart);
    const pizza30sFree = getCountOfPizza30FreeInCart(cart);
    const pizza30sFreeMax = Math.floor(pizza30s / 2);
    const t1 =
      pizza30s === 1
        ? "pizzu Třicítku"
        : pizza30s <= 4
        ? "pizzy Třicítky"
        : "pizz Třicítek";
    const t2 =
      pizza30sFreeMax === 1
        ? "pizzu"
        : pizza30sFreeMax <= 4 && pizza30sFreeMax !== 0
        ? "pizzy"
        : "pizz";
    if (pizza30s === 1 && pizza30sFree !== 0 && pizza30sFreeMax === 0) {
      return (
        "Objednávka obsahuje " +
        pizza30s +
        " " +
        t1 +
        ". Pro pizzu zdarma v rámci Akce 2+1 si do objednávky přidejte další pizzu Třicítku, nebo stávající nadbytečné pizzy zdarma odstraňte."
      );
    }
    if (pizza30sFree > pizza30sFreeMax) {
      return (
        "Objednávka obsahuje " +
        pizza30s +
        " " +
        t1 +
        ". V rámci Akce 2+1 zdarma můžete mít maximálně " +
        pizza30sFreeMax +
        " " +
        t2 +
        " zdarma. Přidejte si do objednávky další pizzy Třicítky, nebo nadbytečné pizzy zdarma odstraňte."
      );
    }
    return null;
  };

  React.useEffect(() => {
    const errors = defaultErrors || [];
    const rules = [validationRule1(), validationRule2(), validationRule3()];
    for (let i = 0; i < rules.length; i++) {
      let error = rules[i];
      if (error) {
        errors.push(error);
      }
    }
    setErrors(errors);
  }, [rangeOfGoods, cart, getTotalQuantity(cart)]);

  const updateDelivery = () => {
    dispatch(deliveryDialogOpen());
  };

  const sortFn = (cartItemA, cartItemB) => {
    const a = cartItemA.product;
    const b = cartItemB.product;
    if (!a.position || !b.position) {
      return 0;
    }
    if (a.position === b.position) {
      return 0;
    }
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  const getItems = (section) => {
    if (!section) {
      return cart.items.sort(sortFn);
    }
    return cart.items
      .filter((cartItem) => {
        return (
          cartItem.product.sections &&
          cartItem.product.sections.includes(section)
        );
      })
      .sort(sortFn);
  };

  if (KIOSK_ENABLED && context === contextHome) {
    return (
      <Box>
        <Box
          mt={1}
          display={"flex"}
          style={{ zoom: 2 }}
          onClick={() => {
            setKioskOpen((prev) => !prev);
          }}
        >
          <Box flexGrow={1}>
            <Box display={"flex"}>
              <Box>{kioskOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
              <Box
                style={{
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                {kioskOpen ? "Skrýt objednávku" : "Zobrazit objednávku"}
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              width: "20%",
            }}
          >
            <Box flexGrow={1}>Cena celkem:</Box>
            <Box>
              <strong>{formatPrice(getCartPriceTotal(cart))}</strong>
            </Box>
          </Box>
        </Box>
        <Collapse in={kioskOpen}>
          <Grid container style={{ marginBottom: 8 * 4 }}>
            {getItems(null).map((cartItem, index) => {
              return (
                <Grid
                  item
                  xs={3}
                  style={{
                    padding: 8,
                    marginBottom: 16,
                  }}
                >
                  <Box
                    style={{
                      border: "solid 2px rgba(255, 0, 0, 0.5)",
                      background: "#fff",
                      borderRadius: 10,
                      paddingTop: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: "100%",
                    }}
                  >
                    <CartItem
                      cartItem={cartItem}
                      editable={editable}
                      key={index}
                      context={context}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          {editable && !handleSubmit && (
            <Box
              mb={1}
              className={classes.button}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Box>
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  style={{ zoom: 1.5, marginLeft: 8 }}
                  onClick={kioskReset}
                >
                  Zrušit objednávku
                </Button>
              </Box>
              <Box>
                {errors.length > 0 && (
                  <Button
                    disabled={true}
                    color={"primary"}
                    variant="contained"
                    size="large"
                    fullWidth={true}
                  >
                    K pokladně
                  </Button>
                )}
                {errors.length === 0 && (
                  <Link to={"/checkout"}>
                    <Button
                      color={"primary"}
                      variant="contained"
                      size="large"
                      fullWidth={false}
                      style={{ zoom: 3 }}
                    >
                      K pokladně
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          )}
        </Collapse>
      </Box>
    );
  }

  if (!cart.items || cart.items.length === 0) {
    return (
      <React.Fragment>
        {dateTimePickerComponent}
        <Typography className={classes.emptyCart}>
          Objednávka je zatím prázdná.
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {[contextAdmin].includes(context) ?
        <>
          <Grid container className={classes.sectionContainer}>
            {vosSections.map((vs) => {
              return (
                <Grid
                  item
                  xs={commissionVariantHorizontal ? 12 : vs.sizeXs}
                  className={classes.section}
                  key={vs.code}
                >
                  <Box className={classes.sectionTitle}>
                    {vs.title}{" "}
                    <CommissionCartSectionButton
                      cart={cart}
                      section={vs}
                      markSectionDone={markSectionDone}
                      handleChangeCommission={handleChangeCommission}
                    />
                  </Box>
                  <Box className={classes.items} style={getPadding(context)}>
                    {getItems(vs.title).map((cartItem, index) => {
                      return (
                        <CartItem
                          cartItem={cartItem}
                          editable={editable}
                          key={index}
                          context={context}
                          cart={cart}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      :
        <>
          <Box className={classes.items} style={getPadding(context)}>
            {getItems(null).map((cartItem, index) => {
              return (
                <CartItem
                  cartItem={cartItem}
                  editable={editable}
                  handleEditCartItem={
                    !!handleEditCartItem
                      ? () => {
                          handleEditCartItem(cartItem, index);
                        }
                      : null
                  }
                  handleToggleTakeAway={
                    !!handleToggleTakeAway
                      ? () => {
                          handleToggleTakeAway(cartItem, index);
                        }
                      : null
                  }
                  key={index}
                  context={context}
                />
              );
            })}
          </Box>
        </>
      }
      <Divider />
      {cart.saleActions?.length > 0 && (
        <Box style={getPadding(context)}>
          {cart.saleActions.map((sa, index) => {
            return (
              <Box className={classes.subtotal} key={index}>
                <Box flexGrow={1}>
                  <div>{sa.title}</div>
                </Box>
                <Box>{formatPrice(sa.price)}</Box>
              </Box>
            );
          })}
          <Divider />
        </Box>
      )}
      <Box className={classes.subtotal} style={getPadding(context)}>
        <Box flexGrow={1}>Mezisoučet</Box>
        <Box>{formatPrice(getCartPriceSubtotal(cart))}</Box>
      </Box>
      {showDeliveryInCart(cart) && (
        <React.Fragment>
          <Box className={classes.subtotal} style={getPadding(context)}>
            <Box flexGrow={1}>
              <div>
                Doručení{" "}
                {!getPointOfSale().isKiosk && editable && (
                  <span className={"changeLink"}>
                    (<a onClick={updateDelivery}>změnit</a>)
                  </span>
                )}
              </div>
              {[contextHome, contextCheckout, contextReport].includes(
                context
              ) && (
                <div className={"address"}>
                  {getDeliveryTextForCart(cart, getPointOfSale())}
                </div>
              )}
            </Box>
            <Box>{formatPrice(getCartPriceDelivery(cart))}</Box>
          </Box>
        </React.Fragment>
      )}

      <Divider />
      <Box className={classes.total} style={getPadding(context)}>
        <Box flexGrow={1}>Celkem</Box>
        <Box>{formatPrice(getCartPriceTotal(cart))}</Box>
      </Box>
      {dateTimePickerComponent}
      {[contextHome, contextCheckout].includes(context) && errors.length > 0 && (
        <>
          {errors.map((error, index) => {
            return (
              <Box className={classes.alert} key={index}>
                <Typography>{error}</Typography>
              </Box>
            );
          })}
        </>
      )}
      {[contextHome].includes(context) &&
        meatSectionProductionInfo_isVisibleForCart(cart) && (
          <Box className={classes.alert}>
            <Typography>{meatSectionProductionInfo_text()}</Typography>
          </Box>
        )}
      {editable && !handleSubmit && (
        <Box className={classes.button}>
          {errors.length > 0 && (
            <Button
              disabled={true}
              color={"primary"}
              variant="contained"
              size="large"
              fullWidth={true}
            >
              K pokladně
            </Button>
          )}
          {errors.length === 0 && (
            <Link to={"/checkout"}>
              <Button
                color={"primary"}
                variant="contained"
                size="large"
                fullWidth={true}
              >
                K pokladně
              </Button>
            </Link>
          )}
        </Box>
      )}
      {editable && handleSubmit && (
        <Box className={classes.button}>
          <Button
            disabled={errors.length > 0 || isButtonDisabled}
            color={"primary"}
            variant="contained"
            size="large"
            fullWidth={true}
            onClick={handleSubmit}
          >
            Založit objednávku
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Cart;
