import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    useTheme,
} from "@material-ui/core";
import {formatPrice, getCartItemPriceTotal} from "../../utils/price";

import React, {useEffect} from "react";
import {
    getAdditionGroupOfAdditionItem,
    getAdditionGroupsOfProduct,
    getAdditionItems,
} from "../../utils/data";
import ProductItemDialogAdditionGroup from "./ProductItemDialogAdditionGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ProductItemDialogAdditionGroupPizzaQuarters from "./ProductItemDialogAdditionGroupPizzaQuarters";
import {
    additionGroupPizzaHalf,
    additionGroupPizzaQuarter,
    additionItemNoSauce,
    product2Plus1Gratis, productBenefit, productPizzaHalf,
    productPizzaQuarter,
} from "../../constants/constants";
import {useSelector} from "react-redux";
import {colors} from "../../theme/theme";
import {sortByPositionFn} from "../../utils/utils";
import {
    meatSectionProductionInfo_isVisibleForProduct,
    meatSectionProductionInfo_text2,
} from "../MeatSectionProductionInfo/MeatSectionProductionInfo";
import ProductItemDialogAdditionGroupPizzaHalfs from "./ProductItemDialogAdditionGroupPizzaHalfs";
import {getCartItemAdditionItemQuantity, getCartItemAdditionItemQuantityKey} from "../../utils/cart";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        "& .title": {
            fontSize: "110%",
            fontWeight: 800,
        },
        "& .subtitle": {
            fontSize: "90%",
            fontWeight: 300,
        },
        "& .description": {
            paddingTop: theme.spacing(0.5),
            fontSize: "70%",
            fontWeight: 300,
        },
        "& .descriptionButton": {
            paddingTop: theme.spacing(0.5),
            fontSize: "70%",
            fontWeight: 300,
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
        padding: theme.spacing(2),
    },
    closeButton: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        "&:hover": {
            backgroundColor: "inherit !important",
        },
    },
    dialogContent: {
        padding: theme.spacing(0),
    },
    dialogActions: {
        padding: theme.spacing(2),
    },
    additionTitle: {
        fontWeight: 200,
    },
    additionPrice: {
        fontWeight: 200,
        textAlign: "right",
        paddingRight: theme.spacing(0.5),
    },
    cartItemPrice: {
        flexGrow: 1,
        fontSize: "120%",
        fontWeight: 300,
    },
    dialogQuantity: {
        display: "inline-block",
        marginRight: theme.spacing(1),
        fontSize: "120%",
        fontWeight: 500,
        "& a": {
            color: colors.RED.main,
            cursor: "pointer",
            textDecoration: "none",
        },
    },
}));

const ProductItemDialog = (props) => {
    const {
        open,
        product,
        handleDialogClose,
        submit,
        initialCartItem = null,
    } = props;

    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const fullScreen = false;

    const isSaleProduct = () => {
        return [product2Plus1Gratis, productBenefit].includes(product.fullTitle);
    };

    const dialogClasses = useStyles();
    const rangeOfGoods = useSelector((state) => state.rangeOfGoods);
    const [cartItem, setCartItem] = React.useState(
        initialCartItem || {
            quantity: 1,
            product,
            additionItems: [],
            takeAway: true,
        }
    );
    const [showDescription, setShowDescription] = React.useState(isSaleProduct());
    const [disabled, setDisabled] = React.useState(false);

    const changeQuantity = (type) => {
        if (type === "add") {
            setCartItem({...cartItem, quantity: cartItem.quantity + 1});
        }
        if (type === "sub" && cartItem.quantity !== 1) {
            setCartItem({...cartItem, quantity: cartItem.quantity - 1});
        }
        return false;
    };

    const onAdditionItemChange = (additionItem, additionGroup, qObject) => {
        if (additionItem.title === additionItemNoSauce) {
            //pokud si zákazník zvolil bez omáčky -> ostatní omáčky odstraňujeme
            setCartItem({
                ...cartItem,
                additionItems: cartItem.additionItems
                    .filter((c) => c.groupTitle !== additionItem.groupTitle)
                    .concat(additionItem),
            });
            return;
        }

        if (qObject.multipleQuantity) {
            const key = getCartItemAdditionItemQuantityKey(additionItem);
            const qty = getCartItemAdditionItemQuantity(cartItem, additionItem, 0);
            let newQty = 0;
            if(qObject.direction === "add"){
                newQty = qty+1;
            }
            if(qObject.direction === "sub" && qty-1 >= 0){
                newQty = qty-1;
            }

            if(newQty > 5){
                return;
            }

            const qAdditionItemsQty = cartItem.hasOwnProperty('additionItemsQty') ? {...cartItem.additionItemsQty} : {};

            if(newQty === 0){
                setCartItem({
                    ...cartItem,
                    additionItems: cartItem.additionItems.filter((c) => c !== additionItem),
                    additionItemsQty: {
                        ...qAdditionItemsQty,
                        [key]: newQty
                    }
                });
            }
            if(qty === 0 && newQty === 1){
                setCartItem({
                    ...cartItem,
                    additionItems: cartItem.additionItems
                        .concat(additionItem),
                    additionItemsQty: {
                        ...qAdditionItemsQty,
                        [key]: newQty
                    }
                });
            }
            if(newQty > 1 || (qty !== 0 && newQty === 1)){
                setCartItem({
                    ...cartItem,
                    additionItemsQty: {
                        ...qAdditionItemsQty,
                        [key]: newQty
                    }
                });
            }


        } else {
            if (cartItem.additionItems.includes(additionItem)) {
                setCartItem({
                    ...cartItem,
                    additionItems: cartItem.additionItems.filter((c) => c !== additionItem),
                });
            } else {
                if (additionGroup.max * 1 === 1 && additionGroup.min * 1 <= 1) {
                    setCartItem({
                        ...cartItem,
                        additionItems: cartItem.additionItems
                            .filter((c) => c.groupTitle !== additionItem.groupTitle)
                            .concat(additionItem),
                    });
                } else {
                    if (
                        additionGroup.max * 1 !==
                        cartItem.additionItems.filter(
                            (c) => c.groupTitle === additionGroup.fullTitle
                        ).length
                    ) {
                        setCartItem({
                            ...cartItem,
                            additionItems: cartItem.additionItems
                                .filter((c) => c.title !== additionItemNoSauce) // pokud si zákazník zvolil omáčku -> odstraňujeme volbu "bez omáčky"
                                .concat(additionItem),
                        });
                    }
                }
            }
        }
    };

    const onQuartersCompleted = (quarters) => {
        setCartItem({
            ...cartItem,
            additionItems: cartItem.additionItems
                .filter((c) => c.groupTitle !== additionGroupPizzaQuarter)
                .concat(quarters),
        });
    };

    const onHalfsCompleted = (halfs) => {
        setCartItem({
            ...cartItem,
            additionItems: cartItem.additionItems
                .filter((c) => c.groupTitle !== additionGroupPizzaHalf)
                .concat(halfs),
        });
    };

    const getButtonLabel = () => {
        return "Vložit do košíku";
    };

    useEffect(() => {
        if (!!initialCartItem) {
            setCartItem(initialCartItem);
        } else {
            setCartItem({
                quantity: 1,
                product,
                additionItems: getAdditionItems(rangeOfGoods)
                    .filter((additionItem) => {
                        return additionItem.invisible === false;
                    })
                    .filter((ai) => {
                        return (
                            ai.isDefaultSelected &&
                            getAdditionGroupsOfProduct(rangeOfGoods, product).includes(
                                getAdditionGroupOfAdditionItem(rangeOfGoods, ai)
                            )
                        );
                    }),
            }); //reset state if dialog is opened
        }
    }, [open, product, rangeOfGoods, initialCartItem]);

    useEffect(() => {
        setDisabled(
            getAdditionGroupsOfProduct(rangeOfGoods, product).filter((ag) => {
                const a = cartItem.additionItems.filter((ai) => {
                    //zjisti počet zvolených položek pro danou skupinu
                    return ai.groupTitle === ag.fullTitle;
                }).length;
                return !(a >= ag.min * 1 && a <= ag.max * 1); //porovnej počet s min a max množstvím pro skupinu
            }).length !== 0
        );
    }, [cartItem, product, rangeOfGoods]);

    const alwaysShowDescription = () => {
        return (
            cartItem.product.title === cartItem.product.description ||
            cartItem.product.description.length < 10
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            fullWidth={true}
            maxWidth={"sm"}
            fullScreen={fullScreen}
        >
            <DialogTitle className={dialogClasses.dialogTitle}>
                <Box style={{display: "flex"}}>
                    <Box style={{flexGrow: 1}}>
                        <Box className={"title"}>{cartItem.product.title}</Box>
                        <Box className={"subtitle"}>{cartItem.product.subtitle}</Box>
                    </Box>
                    <Box>
                        <IconButton
                            className={dialogClasses.closeButton}
                            onClick={handleDialogClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>
                {product.fullTitle !== productPizzaQuarter && product.fullTitle !== productPizzaHalf && (
                    <>
                        <Collapse in={showDescription || alwaysShowDescription()}>
                            <Typography className={"description"}>
                                {cartItem.product.description.replace(/\s/g, " ")}
                            </Typography>
                        </Collapse>
                        {!isSaleProduct() && !alwaysShowDescription() && (
                            <div
                                className={"descriptionButton"}
                                onClick={() => {
                                    setShowDescription(!showDescription);
                                }}
                            >
                                {showDescription ? "Skrýt složení" : "Zobrazit složení"}
                            </div>
                        )}
                    </>
                )}
            </DialogTitle>
            {getAdditionGroupsOfProduct(rangeOfGoods, cartItem.product) && (
                <DialogContent className={dialogClasses.dialogContent}>
                    {getAdditionGroupsOfProduct(rangeOfGoods, cartItem.product)
                        .sort(sortByPositionFn)
                        .map((additionGroup, index) => {
                            if (additionGroup.title === additionGroupPizzaQuarter) {
                                return (
                                    <ProductItemDialogAdditionGroupPizzaQuarters
                                        key={index}
                                        onCompleted={onQuartersCompleted}
                                    />
                                );
                            }
                            if (additionGroup.title === additionGroupPizzaHalf) {
                                return (
                                    <ProductItemDialogAdditionGroupPizzaHalfs
                                        key={index}
                                        onCompleted={onHalfsCompleted}
                                    />
                                );
                            }
                            return (
                                <ProductItemDialogAdditionGroup
                                    key={index}
                                    additionGroup={additionGroup}
                                    onAdditionItemChange={(additionItem, qObject) => {
                                        onAdditionItemChange(additionItem, additionGroup, qObject);
                                    }}
                                    selectedAdditionItems={cartItem.additionItems}
                                    cartItem={cartItem}
                                />
                            );
                        })}
                </DialogContent>
            )}
            <DialogActions
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "normal",
                }}
            >
                {meatSectionProductionInfo_isVisibleForProduct(cartItem.product) && (
                    <Box
                        style={{
                            marginTop: 8,
                            marginBottom: 16,
                            marginLeft: 8,
                            fontWeight: "normal",
                        }}
                    >
                        {meatSectionProductionInfo_text2()}
                    </Box>
                )}
                <Box
                    className={dialogClasses.dialogActions}
                    style={{
                        margin: 0,
                        padding: 8,
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Box className={dialogClasses.cartItemPrice}>
                        Cena:{" "}
                        <strong>{formatPrice(getCartItemPriceTotal(cartItem))}</strong>
                    </Box>
                    {!isSaleProduct() && (
                        <Box className={dialogClasses.dialogQuantity}>
                            <a
                                onClick={() => {
                                    changeQuantity("sub");
                                }}
                            >
                                -
                            </a>
                            &nbsp;
                            {cartItem.quantity}&nbsp;
                            <a
                                onClick={() => {
                                    changeQuantity("add");
                                }}
                            >
                                +
                            </a>
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        size={"large"}
                        disabled={disabled}
                        onClick={() => {
                            submit(cartItem);
                        }}
                    >
                        {getButtonLabel()}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ProductItemDialog;
