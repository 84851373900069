import { baseUrl } from "../constants/constants";

export const apiGetSettings = () => {
  return baseUrl + "/settings";
};

export const apiGetProducts = (pointOfSale, params = []) => {
  return (
    baseUrl +
    "/" +
    pointOfSale +
    "/products" +
    (params.length > 0 ? "?" + params.join("&") : "")
  );
};

export const apiGetAdminCapacity = () => {
  //limitní kapacity
  return baseUrl + "/capacity";
};
export const apiPutAdminCapacity = () => {
  //limitní kapacity
  return baseUrl + "/capacity";
};

export const apiGetCommissions = () => {
  return baseUrl + "/commission";
};

export const apiPostCommission = () => {
  return baseUrl + "/commission";
};

export const apiPutCommission = (id) => {
  return baseUrl + "/commission/" + id;
};

export const apiPutCommissionDotykackaNote = (id) => {
  return baseUrl + "/commission/" + id + "/dotykacka-note";
};

export const apiGetCommission = (id) => {
  return baseUrl + "/commission/" + id;
};

export const apiPostRating = () => {
  return baseUrl + "/rating";
};

export const apiGetCommissionCapacity = () => {
  //aktuální stav kapacit pro objednávky
  return baseUrl + "/commission/capacity";
};

export const apiPostInternalCommission = () => {
  return baseUrl + "/internal-commission";
};
export const apiDeleteInternalCommission = (uid) => {
  return baseUrl + "/internal-commission/" + uid;
};
export const apiGetInternalCommissionCapacity = () => {
  return baseUrl + "/internal-commission/capacity";
};

export const apiPostInternalCommissionPrescription = () => {
  return baseUrl + "/internal-commission-prescription";
};
export const apiDeleteInternalCommissionPrescription = (uid) => {
  return baseUrl + "/internal-commission-prescription/" + uid;
};
export const apiGetInternalCommissionPrescription = () => {
  return baseUrl + "/internal-commission-prescription";
};

export const apiGetInternalCommissionGenerate = () => {
  return baseUrl + "/internal-commission/generate";
};

export const apiLoyaltyGetBenefits = () => {
  return baseUrl + "/loyalty/getBenefits";
};

export const apiLoyaltyGetCustomer = () => {
  return baseUrl + "/loyalty/getCustomer";
};

export const apiLoyaltyAddPoints = () => {
  return baseUrl + "/loyalty/addPoints";
};

export const apiLoyaltyAddBenefit = () => {
  return baseUrl + "/loyalty/addBenefit";
};
