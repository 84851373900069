export const formatDate = (d) => {
    const month = "" + (d.getMonth() + 1);
    const day = "" + d.getDate();
    const year = d.getFullYear();

    return [day, month, year].join(". ");
};

export const timeToHHMM = (h, m) => {
    return (h < 10 ? "0" + h : h) + ":" + (m * 1 < 10 ? "0" + m : m);
};

export const formatTime = (h, m, isInterval, intervalSize) => {
    const d1 = timeToHHMM(h, m);

    if (!isInterval) {
        return d1;
    }

    let h2 = h;
    let m2 = m + intervalSize * 1;
    if (m2 >= 60) {
        h2 = h2 + 1;

        m2 = 0;
    }
    const d2 = timeToHHMM(h2, m2);

    return d1 + " - " + d2;
};

export const getTimeHM = (d) => {
    if (!d) {
        return {
            h: -1, m: -1,
        };
    }
    return {
        h: d.getHours(), m: d.getMinutes(),
    };
};

export const getTimeHHMM = (d) => {
    return timeToHHMM(d.getHours(), d.getMinutes());
};

export const getTimeHHMMSS = (d) => {
    const s = d.getSeconds();
    return (timeToHHMM(d.getHours(), d.getMinutes()) + ":" + (s < 10 ? "0" + s : s));
};

export const getDayNameRelative = (index) => {
    const days = ["dnes", "zítra"];

    return days[index];
};

export const formatDatetimeAdmin = (dString) => {
    const now = new Date();
    const dt = new Date(dString);
    return {
        hhmm: timeToHHMM(dt.getHours(), dt.getMinutes()),
        day: [0, 1].includes(dt.getDate() - now.getDate()) ? getDayNameRelative(dt.getDate() - now.getDate()) : formatDate(dt),
    };
};

export const formatDateFromTimestamp = (timestamp) => {
    const d = new Date(timestamp);
    const h = d.getHours();
    const m = d.getMinutes();
    return formatDate(d) + " " + (h < 10 ? '0' + h : h) + ":" + (m < 10 ? '0' + m : m);
}
