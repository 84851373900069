import {useEffect, useState} from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {
    apiLoyaltyAddBenefit,
    apiLoyaltyAddPoints,
    apiLoyaltyGetBenefits,
    apiLoyaltyGetCustomer
} from "../../../api/endpoints";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import {formatDate, formatDateFromTimestamp} from "../../../utils/dateTime";

const DialogPointTransaction = (props) => {

    const {email, onSubmitted} = props;

    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [value, setValue] = useState(10);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = () => {
        setLoading(true);
        axios.post(apiLoyaltyAddPoints(), {email, description, value}).then((response) => {
            if (response.status === 200) {
                handleClose();
                onSubmitted();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return <>
        <Button variant="contained"
                color="primary" onClick={() => {
            handleOpen();
        }}>Přidat body</Button>
        <Dialog open={open} onClose={() => {
            handleClose();
        }}>
            <DialogTitle>Přidat body pro zákazníka {email}</DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        label="Důvod"
                        variant="outlined"
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                        size={'small'}
                        fullWidth={true}
                        style={{marginTop: 24}}
                    />
                </Box>
                <Box>
                    <TextField
                        type={'number'}
                        label="Body zákazníka"
                        variant="outlined"
                        value={value}
                        onChange={(event) => {
                            setValue(event.target.value);
                        }}
                        size={'small'}
                        fullWidth={true}
                        style={{marginTop: 24}}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant="contained" size={'small'}
                        color="primary" onClick={() => {
                    handleSubmit();
                }}>Přidat body</Button>
            </DialogActions>
        </Dialog>
    </>
}

const DialogBenefit = (props) => {

    const {email, onSubmitted, benefits} = props;

    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [benefitCode, setBenefitCode] = useState(benefits[0].code);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = () => {
        setLoading(true);
        if(benefitCode.length > 2 && description.length > 2){
            axios.post(apiLoyaltyAddBenefit(), {email, description, benefitCode}).then((response) => {
                if (response.status === 200) {
                    handleClose();
                    onSubmitted();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return <>
        <Button variant="contained"
                disabled={true}
                color="primary" onClick={() => {
            handleOpen();
        }}>Přidat dárek</Button>
        <Dialog open={open} onClose={() => {
            handleClose();
        }}>
            <DialogTitle>Přidat dárek pro zákazníka {email}</DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        label="Důvod"
                        variant="outlined"
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                        size={'small'}
                        fullWidth={true}
                        style={{marginTop: 24}}
                    />
                </Box>
                <Box style={{marginTop: 24}}>
                    <FormControl variant={'outlined'} fullWidth size={'small'}>
                        <InputLabel>Dárek</InputLabel>
                        <Select
                            value={benefitCode}
                            label="Dárek"
                            onChange={(event) => {
                                setBenefitCode(event.target.value);
                            }}
                        >
                            {benefits.map((b) => {
                                return (
                                    <MenuItem key={b.code} value={b.code}>{b.title}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant="contained" size={'small'}
                        color="primary" onClick={() => {
                    handleSubmit();
                }}>Přidat dárek</Button>
            </DialogActions>
        </Dialog>
    </>
}

const LoyaltyProgram = () => {

    const [benefits, setBenefits] = useState([]);
    const [email, setEmail] = useState('');
    const [submittedEmail, setSubmittedEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleLoad = () => {
        axios.get(apiLoyaltyGetBenefits()).then((response) => {
            if (response.status === 200) {
                setBenefits(response.data);
            }
        });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSearchCustomer = () => {
        setSubmitted(false);
        setCustomer(null);
        setLoading(true);
        setSubmittedEmail(email);
        axios.post(apiLoyaltyGetCustomer(), {email}).then((response) => {
            if (response.status === 200) {
                setCustomer(response.data);
            }
        }).finally(() => {
            setSubmitted(true);
            setLoading(false);
        });
    };

    const now = new Date();

    useEffect(() => {
        handleLoad();
    }, []);

    return (<>
        <Box>
            <TextField
                label="E-mail zákazníka"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                size={'small'}
                style={{marginRight: 16, width: 250}}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSearchCustomer}
                disabled={loading}
            >
                Najít zákazníka
            </Button>
        </Box>
        {!!submitted && !!customer && <Box style={{marginTop: 32, marginBottom: 32, paddingBottom: 32}}>
            {customer.email !== submittedEmail && <>Zákazník <strong>{submittedEmail}</strong> nebyl nalezen.</>}
            {customer.email === submittedEmail && <>Zákazník <strong>{submittedEmail}</strong> byl nalezen.</>}

            <Box style={{display: "flex", marginTop: 32}}>
                <Typography variant={'h6'} style={{flexGrow: 1}}>Dárky</Typography>
            </Box>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Název</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.benefitCodes.map((code) => {
                        const index = benefits.findIndex((b) => (b.code === code));
                        if(index > -1){
                            const benefit = benefits[index];
                            return (<TableRow key={code}>
                                <TableCell>{benefit.title}</TableCell>
                            </TableRow>)
                        }
                    })}
                </TableBody>
            </Table>

            <Box style={{display: "flex", marginTop: 32}}>
                <Typography variant={'h6'} style={{flexGrow: 1}}>Manuálně přidělené dárky</Typography>
                <DialogBenefit email={email} benefits={benefits} onSubmitted={() => {
                    handleSearchCustomer();
                }}/>
            </Box>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Datum vytvoření</TableCell>
                        <TableCell>Datum expirace</TableCell>
                        <TableCell>Důvod</TableCell>
                        <TableCell>Dárek</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.manualBenefits.sort((a, b) => (b.createdAt - a.createdAt)).map((b) => {
                        return (<TableRow key={b.id}
                                          style={{background: new Date(b.expiredAt * 1000) < now ? 'rgba(255, 0, 0, 0.12)' : 'rgba(0, 255, 0, 0.12)'}}>
                            <TableCell>{formatDateFromTimestamp(b.createdAt * 1000)}</TableCell>
                            <TableCell>{formatDateFromTimestamp(b.expiredAt * 1000)}</TableCell>
                            <TableCell>{b.description}</TableCell>
                            <TableCell>{b.benefitCode}</TableCell>
                        </TableRow>)
                    })}
                </TableBody>
            </Table>

            <Box style={{display: "flex", marginTop: 32}}>
                <Typography variant={'h6'} style={{flexGrow: 1}}>Bodové pohyby</Typography>
                <DialogPointTransaction email={email} onSubmitted={() => {
                    handleSearchCustomer();
                }}/>
            </Box>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Datum vytvoření</TableCell>
                        <TableCell>Datum expirace</TableCell>
                        <TableCell>Důvod</TableCell>
                        <TableCell style={{textAlign: 'right'}}>Body</TableCell>
                        <TableCell style={{textAlign: 'right'}}>Zůstatek</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.pointTransactions.sort((a, b) => (b.createdAt - a.createdAt)).map((pt) => {
                        return (<TableRow key={pt.id}
                                          style={{background: new Date(pt.expiredAt * 1000) < now ? 'rgba(255, 0, 0, 0.12)' : 'rgba(0, 255, 0, 0.12)'}}>
                            <TableCell>{formatDateFromTimestamp(pt.createdAt * 1000)}</TableCell>
                            <TableCell>{pt.value >= 0 ? formatDateFromTimestamp(pt.expiredAt * 1000) : ''}</TableCell>
                            <TableCell>{pt.description}</TableCell>
                            <TableCell style={{textAlign: 'right'}}>{pt.value}</TableCell>
                            <TableCell style={{textAlign: 'right'}}>{pt.balance}</TableCell>
                        </TableRow>)
                    })}
                </TableBody>
            </Table>
        </Box>}
    </>);
}

export default LoyaltyProgram;